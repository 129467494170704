import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'main',
  component: resolve => require(['../views/main.vue'], resolve),
  redirect: '/home',
  children: [{
      path: '/home',
      name: 'home',
      component: resolve => require(['../views/home/index.vue'], resolve)
    },
    {
      path: '/AboutUs',
      name: 'AboutUs',
      component: resolve => require(['../views/AboutUs/index.vue'], resolve)
    }, {
      path: '/contactUs',
      name: 'contactUs',
      component: resolve => require(['../views/contactUs/index.vue'], resolve)
    }, {
      path: '/journalism',
      name: 'journalism',
      component: resolve => require(['../views/journalism/index.vue'], resolve)
    },
    {
      path: '/journalismDetails',
      name: 'journalism_details',
      component: resolve => require(['../views/journalism/components/newsDetails.vue'], resolve),
    }, {
      path: '/product',
      name: 'product',
      component: resolve => require(['../views/product/index.vue'], resolve)
    },
    {
      path: '/CaseDetails',
      name: 'CaseDetails',
      component: resolve => require(['../views/CaseDetails/index.vue'], resolve),

    },
  ]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next();
})

export default router