import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import swiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.js";

// import 'swiper/dist/css/swiper.css';
Vue.use(ElementUI);
Vue.use(swiper);
// Vue.use(swiperSlide);
Vue.config.productionTip = false

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?16514ef550231b7f7af88c79b960988b";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')